import React from 'react';
import { Button, Form, Input, message } from 'antd';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import storage from 'store';

import style from './Login.module.scss';
import { userPower, userInfo } from '@/store/feature/userSlice';
import { ReactComponent as LoginBg } from '@/assets/imgs/login_bg.svg';
import { ReactComponent as Inset } from '@/assets/imgs/inset.svg';
import { ReactComponent as Account } from '@/assets/imgs/account.svg';
import { ReactComponent as Password } from '@/assets/imgs/password.svg';

function Login() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onFinish = async values => {
        let params = { account: values.account, pwd: values.Password, from_type: 2 };
        // let params = { account: '张三', pwd: '123456', from_type: 2 };
        let { code, data, err_msg = '登录失败' } = await React.$API.login(params);

        switch (code) {
            case 0:
                storage.set('AUTHORIZATION', data.jwt);
                dispatch(userPower());
                dispatch(userInfo());

                message.success('登录成功');

                // navigate('/main', { replace: true });
                navigate('/home', { replace: true });
                break;

            default:
                message.error(err_msg);
                break;
        }
        return;
    };

    window.history.pushState(null, null, ''); //登录页时，阻止浏览器后退

    return (
        <div className={style.login_container}>
            <div className={style.login_bg}>
                <LoginBg />
            </div>
            <div className={`${style.login_bg} ${style.login_bg_vice}`}>
                <LoginBg />
            </div>
            <div className={style.inset}>
                <Inset />
            </div>
            <div className={style.from_container}>
                <p>Hello!</p>
                <p>
                    欢迎登录<span>企业微信用户管理系统</span>
                </p>
                <div>
                    <Form
                        style={{ width: '300px' }}
                        name="basic"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <Form.Item
                            name="account"
                            rules={[
                                {
                                    required: true,
                                    message: '账号不能为空!',
                                },
                            ]}
                        >
                            <div className={style.input_box}>
                                <Account />
                                <Input bordered={false} placeholder="请输入用户名" />
                            </div>
                        </Form.Item>

                        <Form.Item
                            style={{ marginTop: '50px' }}
                            name="Password"
                            rules={[
                                {
                                    required: true,
                                    message: '密码不能为空!',
                                },
                            ]}
                        >
                            <div className={style.input_box}>
                                <Password />
                                <Input.Password bordered={false} placeholder="请输入密码" />
                            </div>
                        </Form.Item>

                        <Form.Item style={{ marginTop: '50px' }}>
                            <div className={style.submit}>
                                <Button type="primary" htmlType="submit">
                                    登录
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    );
}
export default Login;
