/* eslint-disable import/no-anonymous-default-export */
export default ({ request }) => ({
    getUserList(data) {
        return request({
            url: 'admin/user/list',
            method: 'post',
            data,
        });
    },
});
