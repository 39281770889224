import { Navigate } from 'react-router-dom';

import Login from '@/views/login/Login';
import Pege404 from '@/views/errorPage/404';
import Posters from '@/views/posters/Posters';

const whiteList = [
    {
        path: '/',
        element: <Navigate to="/login" replace={true} />,
    },
    {
        path: '/login',
        element: <Login />,
    },
    {
        path: '*',
        element: <Pege404 />,
    },
    {
        path: 'posters',
        element: <Posters />,
    },
];

export default whiteList;
