// eslint-disable-next-line import/no-anonymous-default-export
export default ({ request }) => ({
    getWelcomeMessageList(params) {
        return request({
            url: 'admin/welcome_message/list',
            method: 'get',
            params,
        });
    },
    commonMaterialUpload(data) {
        return request({
            url: 'admin/common/material_upload',
            method: 'post',
            data,
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    },
    welcomeMessageAdd(data) {
        return request({
            url: 'admin/welcome_message/add',
            method: 'post',
            data,
        });
    },
    welcomeMessageEdit(data) {
        return request({
            url: 'admin/welcome_message/edit',
            method: 'post',
            data,
        });
    },
    welcomeMessageAddEditStatus(params) {
        return request({
            url: 'admin/welcome_message/edit_status',
            method: 'get',
            params,
        });
    },
    getWelcomeMessageGetSelectList() {
        return request({
            url: 'admin/welcome_message/get_select_list',
            method: 'get',
        });
    },
    getSelectOa(data) {
        return request({
            url: 'admin/oa/get_select_oa',
            method: 'post',
            data,
        });
    },
    getWelcomeMessage(params) {
        return request({
            url: 'admin/welcome_message/info_welcome',
            method: 'get',
            params,
        });
    },
});
