/* eslint-disable import/no-anonymous-default-export */
const Advanced = ()=>(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 17C8.38071 17 9.5 15.8807 9.5 14.5C9.5 13.1193 8.38071 12 7 12C5.61929 12 4.5 13.1193 4.5 14.5C4.5 15.8807 5.61929 17 7 17Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17 17C18.3807 17 19.5 15.8807 19.5 14.5C19.5 13.1193 18.3807 12 17 12C15.6193 12 14.5 13.1193 14.5 14.5C14.5 15.8807 15.6193 17 17 17Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 7C13.3807 7 14.5 5.88071 14.5 4.5C14.5 3.11929 13.3807 2 12 2C10.6193 2 9.5 3.11929 9.5 4.5C9.5 5.88071 10.6193 7 12 7Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 22C12 19.2386 9.7614 17 7 17C4.23857 17 2 19.2386 2 22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M22 22C22 19.2386 19.7614 17 17 17C14.2386 17 12 19.2386 12 22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17 12C17 9.2386 14.7614 7 12 7C9.2386 7 7 9.2386 7 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

export default {Advanced}
