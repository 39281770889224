import React from 'react';
import storage from 'store';
import { message } from 'antd';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    user_info: storage.get('userInfo') ? storage.get('userInfo') : '',
    jurisdiction: [],
    initPower: [],
    loading: false,
    isLogin: storage.get('isLogin') ? storage.get('isLogin') : false,
};

export const userPower = createAsyncThunk('getUserPower', async () => {
    let data = await React.$API.getPower();
    return data;
});
export const userInfo = createAsyncThunk('getUserInfo', async () => {
    let data = await React.$API.getInfo();
    return data;
});

const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {
        SetUserPower: (state, action) => {
            state.jurisdiction = action.payload;
        },
        DelUserInfo: state => {
            state.user_info = '';
            state.jurisdiction = [];
            state.isLogin = false;
            storage.remove('isLogin');
            storage.remove('userInfo');
            storage.remove('token');
            storage.remove('mergeMenu');
            storage.remove('initPower');
        },
    },
    extraReducers: builder => {
        builder
            //获取菜单列表
            .addCase(userPower.pending, state => {
                state.loading = true;
            })
            .addCase(userPower.fulfilled, (state, { payload }) => {
                const { code, data, err_msg = '请求错误' } = payload;
                if (code === 0) {
                    state.initPower = data;
                    storage.set(
                        'initPower',
                        data.map(item => item.id)
                    );
                    state.jurisdiction = data
                        .map(
                            item =>
                                !item.pid &&
                                (item = Object.assign({}, item, {
                                    children: data.filter(el => {
                                        if (item.id === el.pid) el.btnMenu = data.filter(btn => el.id === btn.pid).filter(btn => btn);
                                        return item.id === el.pid;
                                    }),
                                }))
                        )
                        .filter(item => item);
                } else {
                    message.error(err_msg);
                }

                state.loading = false;
            })
            .addCase(userPower.rejected, state => {
                state.loading = false;
            });
        //获取用户信息
        builder.addCase(userInfo.fulfilled, (state, { payload }) => {
            const { code, data } = payload;
            if (code === 0) {
                state.user_info = data;
                state.isLogin = true;
                storage.set('userInfo', data);
                storage.set('isLogin', true);
            }
        });
    },
});

export const { SetUserPower, DelUserInfo } = userSlice.actions;

export default userSlice.reducer;
