import React from 'react';
import { useNavigate } from 'react-router-dom';

import style from './errPage.module.scss';
import { ReactComponent as Page403 } from '@/assets/imgs/err_page/403.svg';

export default function ErrPage403() {
    const navigate = useNavigate();

    const handelRollback = () => {
        navigate('/home', { replace: true });
    };
    return (
        <div className={style.err_page}>
            <div>
                <Page403 />
            </div>
            <p>您还没有权限访问当前页面</p>
            <button onClick={handelRollback}>刷新页面</button>
        </div>
    );
}
