// eslint-disable-next-line import/no-anonymous-default-export
export default ({ request }) => ({
    getMsgList(params) {
        return request({
            url: 'admin/msg/list',
            method: 'get',
            params,
        });
    },
    getTagSelectList(params) {
        return request({
            url: 'admin/tag/get_select_tag',
            method: 'get',
            params,
        });
    },
    msgAddMsgSend(data) {
        return request({
            url: 'admin/msg/add_msg_send',
            method: 'post',
            data,
        });
    },
    delMsgSend(params) {
        return request({
            url: 'admin/msg/del_msg_send',
            method: 'get',
            params,
        });
    },
    msgSendMsg(params) {
        return request({
            url: 'admin/msg/send_msg',
            method: 'get',
            params,
        });
    },
    msgSendMsgLog(params) {
        return request({
            url: 'admin/msg/send_msg_log',
            method: 'get',
            params,
        });
    },
    tagFilterUser(params) {
        return request({
            url: 'admin/tag/tag_filter_user',
            method: 'get',
            params,
            timeout:0
        });
    },
    msgAllMsgData(params) {
        return request({
            url: 'admin/msg/all_msg_data',
            method: 'get',
            params,
        });
    },
    msgData(params) {
        return request({
            url: 'admin/msg/msg_data',
            method: 'get',
            params,
        });
    },
    msgEditStatus(params) {
        return request({
            url: 'admin/msg/edit_status',
            method: 'get',
            params,
        });
    },
    checkUserInfo(params) {
        return request({
            url: 'admin/user/get_user_info',
            method: 'get',
            params,
        });
    },
    sendMsgToUser(params) {
        return request({
            url: 'admin/msg/send_msg_to_user',
            method: 'get',
            params,
        });
    },
    msgEditMsgSend(data) {
        return request({
            url: 'admin/msg/edit_msg_send',
            method: 'post',
            data,
        });
    },
});
