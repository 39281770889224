/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
// react动态加载组件 @loadable/component
import { useLocation } from 'react-router-dom';
import storage from 'store';

import { useSelector, useDispatch } from 'react-redux';
import { userPower, userInfo } from '@/store/feature/userSlice';

import { mergeRouterList } from './list';

const PrivateRoute = () => {
    const { jurisdiction } = useSelector(state => state.userSlice);
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    useEffect(() => {
        if (!jurisdiction.length && !RegExp(/login/).test(pathname)) {
            storage.remove('mergeMenu');
            storage.remove('initPower');
            dispatch(userPower());
            dispatch(userInfo());
        }
    }, []);
    // let redirect = '/home'; //重定向
    // let crumble = [];
    // let pash_head = '/home';
    // if (jurisdiction.length && jurisdiction[0].children && jurisdiction[0].children.length) {
    //     redirect = pash_head + jurisdiction[0].children[0].path;
    //     crumble = [jurisdiction[0].title, jurisdiction[0].children[0].title];
    // } else {
    //     redirect = jurisdiction.length ? pash_head + jurisdiction[0].path.slice(0, jurisdiction[0].path.lastIndexOf('/')) : redirect;
    //     crumble = jurisdiction.length ? [jurisdiction[0].title] : crumble;
    // }

    return useRoutes(mergeRouterList());
};

export default PrivateRoute;
