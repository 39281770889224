// eslint-disable-next-line import/no-anonymous-default-export
export default ({ request }) => ({
    getKfList(params) {
        return request({
            url: 'admin/kf/list',
            method: 'get',
            params,
        });
    },
    kfUpdateAvatar(data) {
        return request({
            url: 'admin/kf/update_avatar',
            method: 'post',
            data,
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    },
    kfAdd(data) {
        return request({
            url: 'admin/kf/add',
            method: 'post',
            data,
        });
    },
    weChatAccountDel(params) {
        return request({
            url: 'admin/account/del',
            method: 'get',
            params,
        });
    },
    weChatKfEditStatus(params) {
        return request({
            url: 'admin/kf/edit_status',
            method: 'get',
            params,
        });
    },
    weChatKfEdit(data) {
        return request({
            url: 'admin/kf/edit',
            method: 'post',
            data,
        });
    },
    weChatKSyncKf(params) {
        return request({
            url: 'admin/kf/sync_kf',
            method: 'get',
            params,
        });
    },
});
