/* eslint-disable import/no-anonymous-default-export */
const SmallProgram = ()=>(
    <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2630_27972)">
        <path d="M4.5 8.25C6.57107 8.25 8.25 6.57107 8.25 4.5C8.25 2.42893 6.57107 0.75 4.5 0.75C2.42893 0.75 0.75 2.42893 0.75 4.5C0.75 6.57107 2.42893 8.25 4.5 8.25Z" stroke="#7686DB" strokeWidth="0.625" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5.4375 4.5C5.62292 4.5 5.80418 4.44503 5.95836 4.34201C6.11252 4.23898 6.23269 4.09256 6.30364 3.92126C6.37459 3.74996 6.39317 3.56147 6.35698 3.37961C6.32081 3.19774 6.23153 3.0307 6.10041 2.8996C5.96931 2.76848 5.80226 2.67919 5.62041 2.64302C5.43853 2.60683 5.25004 2.62541 5.07874 2.69636C4.90744 2.76731 4.76102 2.88748 4.65801 3.04166C4.55498 3.19583 4.5 3.37708 4.5 3.5625V5.4375C4.5 5.62292 4.44503 5.80418 4.34201 5.95836C4.23898 6.11252 4.09258 6.23269 3.92126 6.30364C3.74996 6.37459 3.56147 6.39317 3.37961 6.35698C3.19774 6.32081 3.0307 6.23153 2.8996 6.10041C2.76848 5.96931 2.67919 5.80226 2.64302 5.62041C2.60683 5.43853 2.62541 5.25004 2.69636 5.07874C2.76731 4.90744 2.88748 4.76102 3.04166 4.65801C3.19583 4.55498 3.37708 4.5 3.5625 4.5" stroke="#7686DB" strokeWidth="0.625" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_2630_27972">
        <rect width="9" height="9" fill="white"/>
        </clipPath>
        </defs>
    </svg>
)

export default {SmallProgram}