import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Login from '../views/login/Login';
import Posters from '@/views/posters/Posters';
import Routes from './index';

function AppRouter() {
    const { isLogin } = useSelector(state => state.userSlice);
    const { pathname } = useLocation();

    if (RegExp(/posters/).test(pathname)) {
        return <Posters />;
    }
    if (!isLogin) {
        return <Login />;
    }
    return <Routes />;
}

export default AppRouter;
