// eslint-disable-next-line import/no-anonymous-default-export
export default ({ request }) => ({
    getCompanyList(params) {
        return request({
            url: 'admin/company/list',
            method: 'get',
            params,
        });
    },
    CompanyEditStatus(params) {
        return request({
            url: 'admin/company/edit_status',
            method: 'get',
            params,
        });
    },
    getCompanyQrCode() {
        return request({
            url: 'admin/company/qr_code',
            method: 'get',
        });
    },
});
