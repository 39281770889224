// eslint-disable-next-line import/no-anonymous-default-export
export default ({ request }) => ({
    getChannelList(params) {
        return request({
            url: 'admin/channel/list',
            method: 'get',
            params,
        });
    },
    ChannelEditStatus(params) {
        return request({
            url: 'admin/channel/edit_status',
            method: 'get',
            params,
        });
    },
    getWelcomeMessageSelectList(params) {
        return request({
            url: 'admin/welcome_message/get_select_list',
            method: 'get',
            params,
        });
    },
    channelNnbind(params) {
        return request({
            url: 'admin/channel/unbind',
            method: 'get',
            params,
        });
    },
    channelAdd(data) {
        return request({
            url: 'admin/channel/add',
            method: 'post',
            data,
        });
    },
    getChannelUrl(params) {
        return request({
            url: 'admin/channel/get_channel_url',
            method: 'get',
            params,
        });
    },
    getChannelUserDataList(params) {
        return request({
            url: 'admin/channel/channel_user_data_list',
            method: 'get',
            params,
        });
    },
    getChannelUserGatherData(params) {
        return request({
            url: 'admin/channel/user_gather_data',
            method: 'get',
            params,
        });
    },
    getChannelUserClickData(params) {
        return request({
            url: 'admin/channel/click_channel_gather_date_list',
            method: 'get',
            params,
        });
    },
    getChannelUserClickgather(params) {
        return request({
            url: 'admin/channel/click_channel_gather',
            method: 'get',
            params,
        });
    },
    getChannelUserOrderData(params) {
        return request({
            url: 'admin/channel/channel_order_gather_date_list',
            method: 'get',
            params,
        });
    },
    getChannelUserOrdergather(params) {
        return request({
            url: 'admin/channel/order_gather_data',
            method: 'get',
            params,
        });
    },
    getChannelUser(params) {
        return request({
            url: 'admin/channel/channel_user',
            method: 'get',
            params,
        });
    },
    getChannelKeepUserData(params) {
        return request({
            url: 'admin/channel/channel_user_date_list',
            method: 'get',
            params,
        });
    },
    getChannelMpgUrl(params) {
        return request({
            url: 'admin/channel/get_channel_mpg_url',
            method: 'get',
            params,
        });
    },
    getChannelVideoCode(params) {
        return request({
            url: 'admin/channel/get_channel_video_code',
            method: 'get',
            params,
        });
    },
    getChannelId(params) {
        return request({
            url: 'admin/channel/channel_account',
            method: 'get',
            params,
        });
    },
    getChannelUnbind(params) {
        return request({
            url: 'admin/channel/unbind_channel_account',
            method: 'get',
            params,
        });
    },
    getfreeAccount(params) {
        return request({
            url: 'admin/account/free_account',
            method: 'get',
            params,
        });
    },
    getCustomerAcquisitionUrl(params) {
        return request({
            url: 'admin/channel/get_customer_acquisition_url',
            method: 'get',
            params,
        });
    },
    getbindOutChannel(params) {
        return request({
            url: 'admin/channel/bind_out_channel',
            method: 'get',
            params,
        });
    },
    getChannelReport(params) {
        return request({
            url: 'admin/channel/channel_report',
            method: 'get',
            params,
        });
    },
    getChannelReportData(params) {
        return request({
            url: 'admin/channel/channel_report_data',
            method: 'get',
            params,
        });
    },
});
