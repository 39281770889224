// eslint-disable-next-line import/no-anonymous-default-export
export default ({ request }) => ({
    getShUserList(params) {
        return request({
            url: 'admin/user/get_sh_user_list',
            method: 'get',
            params,
        });
    },
    EditUserType(params) {
        return request({
            url: 'admin/user/edit_user_type',
            method: 'get',
            params,
        });
    },
    userOrderList(params) {
        return request({
            url: 'admin/user/user_order_list',
            method: 'get',
            params,
        });
    },
    userChannel(params) {
        return request({
            url: 'admin/user/user_channel',
            method: 'get',
            params,
        });
    },
    userOpenid(params) {
        return request({
            url: 'admin/user/user_openid',
            method: 'get',
            params,
        });
    },
    getUserIp(params) {
        return request({
            url: 'admin/user/get_user_ip',
            method: 'get',
            params,
        });
    },
});
