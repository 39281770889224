// eslint-disable-next-line import/no-anonymous-default-export
export default ({ request }) => ({
    getOaListActivity(params) {
        return request({
            url: 'admin/oa/list_activity',
            method: 'get',
            params,
        });
    },
    addOaCreateActivity(data) {
        return request({
            url: 'admin/oa/create_activity',
            method: 'post',
            data,
        });
    },
    getOalistCommand(params) {
        return request({
            url: 'admin/oa/list_command',
            method: 'get',
            params,
        });
    },
    addOaCreateCommand(data) {
        return request({
            url: 'admin/oa/create_command',
            method: 'post',
            data,
        });
    },
    miniProgramUrl(params) {
        return request({
            url: 'admin/mini_program/url',
            method: 'get',
            params,
        });
    },
});
