// react动态加载组件 @loadable/component
import loadable from '@loadable/component';

// 账户管理
const EnterpriseManage = loadable(() => import('@/views/accountManage/EnterpriseManage'));
const ServiceManage = loadable(() => import('@/views/accountManage/ServiceManage'));
const WeChatServiceManage = loadable(() => import('@/views/accountManage/WeChatServiceManage'));
const ServiceTransferManage = loadable(() => import('@/views/accountManage/ServiceTransferManage'));

// 用户管理
const UserList = loadable(() => import('@/views/userManage/UserManage'));
const AuditList = loadable(() => import('@/views/userManage/AuditList'));

// 客服扩充
const ServiceList = loadable(() => import('@/views/serviceExtend/ServiceExtend'));

// 欢迎语
const WelcomeList = loadable(() => import('@/views/welcomes/Welcomes'));
const WeChatService = loadable(() => import('@/views/welcomes/WeChatService'));

// 标签管理
const TagStock = loadable(() => import('@/views/tagManage/TagManage'));

// 高级群发
const AdvancedFSendList = loadable(() => import('@/views/advancedFSend/AdvancedFSend'));
const TimeDelay = loadable(() => import('@/views/advancedFSend/TimeDelay'));
const MassContent = loadable(() => import('@/views/advancedFSend/MassContent'));
const SendTask = loadable(() => import('@/views/advancedFSend/SendTask'));

const list_2 = [
    {
        icon: 'Account',
        name: '账户管理',
        path: 'accountManage',
        id: 17,
        pid: 0,
        children: [
            {
                id: 71,
                pid: 17,
                name: '企业管理',
                path: 'EnterpriseManage',
                element: <EnterpriseManage />,
            },
            {
                id: 72,
                pid: 17,
                name: '客服号管理',
                path: 'ServiceManage',
                element: <ServiceManage />,
            },
            {
                id: 73,
                pid: 17,
                name: '微信客服管理',
                path: 'WeChatServiceManage',
                element: <WeChatServiceManage />,
            },
            {
                id: 74,
                pid: 17,
                name: '客服迁移管理',
                path: 'ServiceTransferManage',
                element: <ServiceTransferManage />,
            },
        ],
    },

    {
        icon: 'User',
        name: '用户管理',
        path: 'userManage',
        id: 18,
        pid: 0,
        children: [
            {
                id: 75,
                pid: 18,
                name: '用户列表',
                path: 'userList',
                element: <UserList />,
            },
            {
                id: 142,
                pid: 18,
                name: '审核列表',
                path: 'auditList',
                element: <AuditList />,
            },
        ],
    },

    {
        icon: 'Service',
        name: '客服扩充',
        path: 'serviceExtend',
        id: 19,
        pid: 0,
        children: [
            {
                id: 78,
                pid: 19,
                name: '客服列表',
                path: 'serviceList',
                element: <ServiceList />,
            },
        ],
    },
    {
        icon: 'Welcome',
        name: '欢迎语',
        path: 'welcomes',
        id: 20,
        pid: 0,
        children: [
            {
                id: 79,
                pid: 20,
                name: '客服号',
                path: 'welcomeList',
                element: <WelcomeList />,
            },
            {
                id: 145,
                pid: 20,
                name: '微信客服',
                path: 'weChatService',
                element: <WeChatService />,
            },
        ],
    },
    {
        icon: 'Tag',
        name: '标签管理',
        path: 'tagManage',
        id: 21,
        pid: 0,
        children: [
            {
                id: 80,
                pid: 21,
                name: '标签库',
                path: 'tagStock',
                element: <TagStock />,
            },
        ],
    },
    {
        icon: 'Advanced',
        name: '高级群发',
        path: 'advancedFSend',
        id: 22,
        pid: 0,
        children: [
            {
                id: 81,
                pid: 22,
                name: '群发列表',
                path: 'advancedFSendList',
                element: <AdvancedFSendList />,
            },
            {
                id: 152,
                pid: 22,
                name: '群发列表',
                path: 'timeDelay',
                element: <TimeDelay />,
            },
            {
                id: 170,
                pid: 22,
                name: '群发内容',
                path: 'massContent',
                element: <MassContent />,
            },
            {
                id: 171,
                pid: 22,
                name: '发送任务',
                path: 'sendTask',
                element: <SendTask />,
            },
        ],
    },
];

export default list_2;
