// eslint-disable-next-line import/no-anonymous-default-export
export default ({ request }) => ({
    getSendTaskList(params) {
        return request({
            url: 'admin/send_task/list',
            method: 'get',
            params,
        });
    },
    getMsgContentSelect(params) {
        return request({
            url: 'admin/msg_content/get_select_list',
            method: 'get',
            params,
        });
    },
    addSendTask(data) {
        return request({
            url: 'admin/send_task/add',
            method: 'post',
            data,
        });
    },
    sendTaskLog(params) {
        return request({
            url: 'admin/send_task/send_task_log',
            method: 'get',
            params,
        });
    },
    sendTaskAllMsgData(params) {
        return request({
            url: 'admin/send_task/all_msg_data',
            method: 'get',
            params,
        });
    },
    sendTaskMsgData(params) {
        return request({
            url: 'admin/send_task/msg_data',
            method: 'get',
            params,
        });
    },
    sendTaskDel(params) {
        return request({
            url: 'admin/send_task/del',
            method: 'get',
            params,
        });
    },
    sendTaskEditStatus(params) {
        return request({
            url: 'admin/send_task/edit_status',
            method: 'get',
            params,
        });
    },
    taskGetCchannelUser(data) {
        return request({
            url: 'admin/channel/get_channel_user',
            method: 'post',
            data,
        });
    },
});
