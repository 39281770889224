/* eslint-disable import/no-anonymous-default-export */
const Del = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1203_26665)">
            <path d="M3 3.33301V14.6663H13V3.33301H3Z" stroke="currentColor" strokeLinejoin="round" />
            <path d="M6.66699 6.66602V10.9993" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.33301 6.66602V10.9993" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1.33301 3.33398H14.6663" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.33301 3.33398L6.42934 1.33398H9.59204L10.6663 3.33398H5.33301Z" stroke="currentColor" strokeLinejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_1203_26665">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default { Del };
