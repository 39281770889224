// eslint-disable-next-line import/no-anonymous-default-export
export default ({ request }) => ({
    getSystemKfWelcomeConfig() {
        return request({
            url: 'admin/system/get_kf_welcome_config',
            method: 'get',
        });
    },
    systemEditKfWelcomeConfig(data) {
        return request({
            url: 'admin/system/edit_kf_welcome_config',
            method: 'post',
            data,
        });
    },
});
