// eslint-disable-next-line import/no-anonymous-default-export
export default ({ request }) => ({
    getServiceList(params) {
        return request({
            url: 'admin/account/list',
            method: 'get',
            params,
        });
    },
    editMaxFrendsNum(params) {
        return request({
            url: 'admin/account/edit_max_frends_num',
            method: 'get',
            params,
        });
    },
    editAccountStatus(params) {
        return request({
            url: 'admin/account/edit_status',
            method: 'get',
            params,
        });
    },
    getAccountBuildOauthUrl(params) {
        return request({
            url: 'admin/account/build_oauth_url',
            method: 'get',
            params,
        });
    },
    changeNovelPlatform(data) {
        return request({
            url: 'admin/account/change_novel_platform',
            method: 'post',
            data,
        });
    },
    editHkzsStatus(data) {
        return request({
            url: 'admin/account/edit_hkzs_status',
            method: 'post',
            data,
        });
    },
    moreDel(data) {
        return request({
            url: 'admin/account/del',
            method: 'post',
            data,
        });
    },
});
