import axios from 'axios';
import storage from 'store';
import { message } from 'antd';

import { BASE_URL, TIMEOUT } from './config';

let flag = false;

const service = axios.create({
    baseURL: BASE_URL, // url = base url + request url
    timeout: TIMEOUT, // request timeout
});

// 异常拦截处理器
const errorHandler = error => {
    return Promise.reject(error);
};

// request interceptor
service.interceptors.request.use(config => {
    const token = storage.get('AUTHORIZATION');
    // 如果 token 存在
    // 让每个请求携带自定义 token 请根据实际情况自行修改
    if (token) {
        config.headers = {
            'Content-Type': 'application/json',
            AUTHORIZATION: token, //需要使用的token,
            ...config.headers,
        };
    }
    return config;
}, errorHandler);

// response interceptor
service.interceptors.response.use(async response => {
    const res = response.data;
    if (res.code === 4001) {
        //错误token
        if (!flag) {
            flag = true;
            window.history.replaceState('', null, '/#/login');
            message.error('登录失效');
            setTimeout(() => {
                window.location.reload();
            }, 1500);
            setTimeout(() => {
                flag = false;
            }, 3000);
            return Promise.reject(res);
        }
    }
    if (res.code === 4002) {
        //登录过期刷新token
        try {
            const token = storage.get('AUTHORIZATION');
            let result = await axios({
                // url: BASE_URL + 'admin/manager/token_refurbish',
                url: 'https://work.api.sczsie.cn/admin/manager/token_refurbish',
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    AUTHORIZATION: token,
                },
            });
            if (result.data.code === 0) {
                storage.set('AUTHORIZATION', result.data.data.jwt);
                response.config.headers.AUTHORIZATION = storage.get('AUTHORIZATION');
                return service(response.config);
            } else {
                if (!flag) {
                    flag = true;
                    window.history.replaceState('', null, '/#/login');
                    message.error('登录失效');
                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                    setTimeout(() => {
                        flag = false;
                    }, 3000);
                    return Promise.reject(result);
                }
            }
        } catch (error) {
            if (!flag) {
                flag = true;
                window.history.replaceState('', null, '/#/login');
                message.error('登录过期');
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
                setTimeout(() => {
                    flag = false;
                }, 3000);
                return Promise.reject(error);
            }
        }
    }

    return res;
}, errorHandler);

export default service;
