import React from 'react';
import ReactDOM from 'react-dom/client';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';

// 由于 antd 组件的默认文案是英文，所以需要修改为中文
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import zhCN from 'antd/locale/zh_CN';

import "./assets/styles/globalStyle.scss"
import App from './App';
import store from './store';
import apis from './api/index';
import { useCallbackState } from './hook/useCallbackState'; //同步获取useState

dayjs.locale('zh-cn');

React.useCallbackState = useCallbackState;
React.$API = apis;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ConfigProvider
        locale={zhCN}
        theme={{
            token: {
                colorPrimary: '#504EBE',
                // colorLink:'#504EBE',
                // colorLinkHover:'#8C8BDC',
                // colorLinkActive:'4544BF',
                borderRadius: 2,
            },
        }}
    >
        <Provider store={store}>
            <App />
        </Provider>
    </ConfigProvider>
);
