// eslint-disable-next-line import/no-anonymous-default-export
export default ({ request }) => ({
    getAccountList(params) {
        return request({
            url: 'admin/manager/list',
            method: 'get',
            params
        })
    },
    searchRoleList(params) {
        return request({
            url: 'admin/role/get_role_list',
            method: 'get',
            params
        })
    },
    managerAdd(data) {
        return request({
            url: '/admin/manager/add',
            method: 'post',
            data
        })
    },
    editorAccount(data) {
        return request({
            url: '/admin/manager/edit',
            method: 'post',
            data
        })
    },
    delAccount(params) {
        return request({
            url: '/admin/manager/del',
            method: 'get',
            params
        })
    },
    
});