/* eslint-disable import/no-anonymous-default-export */
const Edit = ()=>(
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.3337 7.66732V4.66732L10.3337 1.33398H3.33366C2.96547 1.33398 2.66699 1.63246 2.66699 2.00065V14.0007C2.66699 14.3689 2.96547 14.6673 3.33366 14.6673H7.33366" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.6663 14.6667L13.9997 11.3333L12.6663 10L9.33301 13.3333V14.6667H10.6663Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10 1.33301V4.66634H13.3333" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

)

export default {Edit}