import React from "react";

import style from "./index.module.scss";
import logo from "@/assets/imgs/logo.svg";

function Title() {
  return (
    <div className={style.title}>
      <span className={style.main_title}>企微用户系统</span>
      <span className={style.subtitle}>Enterprise micro user system</span>
    </div>
  );
}

export default function index(props) {
  return (
    <div className={style.logo_content}>
      <img src={logo} alt='' draggable={false}/>
      {!props.collapsed ? <Title /> : null}
    </div>
  );
}
