import { useEffect } from 'react';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import Icon from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import storage from 'store';

import './index.scss';
import Logo from './Logo';
import Loading from './loading/Loading';

import { SetHistory } from '../../../store/feature/historySlice';
import { powerList } from '@/router/list';
import Icons from '@/assets/myIcon';

export default function MenuBar(props) {
    const { jurisdiction } = useSelector(state => state.userSlice);
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const setPash = params => '/home' + params;

    // 初始化需展开的导航 和 高亮当前路径的导航
    // 其中 openMenus 存储当前选中的路径，是个数组
    //
    // 而 selectedOpenMenus 存储的是当前要打开的路径，如果当前选中三级菜单节点，
    // 那么这个打开的路径就应该是['一级菜单节点路径'，'二级菜单节点路径']。
    // 依次类推。
    const rank = pathname.split('/').slice(1, pathname.split('/').length);
    const len = rank.length;
    const openMenus = [pathname];
    const selectedOpenMenus = [];
    const index = len - 2;
    if (len > 0) {
        for (let i = 0; i < index + 1; i++) {
            if (i < index) {
                selectedOpenMenus.push('/' + rank.slice(0, i + 2).join('/'));
            }
        }
    }

    useEffect(() => {
        if (pathname !== '/home') {
            dispatch(SetHistory({ jurisdiction: storage.get('mergeMenu'), pathname }));
        }
    });

    function mergeMenu(routelist, power) {
        let menu = [];
        function filterMenu(list, power, pel = '') {
            let arr = [];
            if (Array.isArray(list) && list.length) {
                list.forEach(item => {
                    power.some(prr => {
                        if (item.id === prr.id) {
                            if (item.children && item.children.length) {
                                let icon = prr.icon;
                                let path = '/' + prr.path;
                                arr.push({ ...item, icon, path, children: filterMenu(item.children, prr.children, path) });
                            } else {
                                if (prr.icon) {
                                    let icon = prr.icon;
                                    let path = pel + '/' + prr.path;
                                    arr.push({ ...item, icon, path });
                                } else {
                                    let path = pel + '/' + prr.path;
                                    arr.push({ ...item, path });
                                }
                            }
                        }
                        return item.id === prr.id;
                    });
                });
            }
            return arr;
        }
        function finishingMenu(routelist) {
            let arr = [];
            routelist.length &&
                routelist.forEach(item => {
                    let IconNode = '';
                    item.icon ? (IconNode = Icons[item.icon]) : (IconNode = '');

                    if (item.children && item.children.length) {
                        //菜单图标
                        arr.push({
                            key: setPash(item.path),
                            icon: <Icon component={IconNode} />,
                            label: item.name,
                            children: [...finishingMenu(item.children)],
                        });
                    } else {
                        if (item.icon) {
                            arr.push({
                                key: setPash(item.path),
                                icon: <Icon component={IconNode} />,
                                label: <Link to={setPash(item.path)}>{item.name}</Link>,
                            });
                        } else {
                            arr.push({
                                key: setPash(item.path),
                                label: <Link to={setPash(item.path)}>{item.name}</Link>,
                            });
                        }
                    }
                });
            return arr;
        }

        let menuLisr = filterMenu(routelist, power);
        if (menuLisr.length) {
            storage.set('mergeMenu', menuLisr);
            menu = finishingMenu(menuLisr);
        }
        return menu;
    }

    return (
        <div style={{ position: 'relative' }}>
            <Logo collapsed={props.collapsed} />
            <Loading />
            <Menu
                theme="light"
                mode="inline"
                defaultOpenKeys={selectedOpenMenus}
                defaultSelectedKeys={openMenus}
                selectedKeys={openMenus}
                items={mergeMenu(jurisdiction, powerList)}
            />
        </div>
    );
}
