import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';

import style from './History.module.scss';
import { SetDelete } from '@/store/feature/historySlice';

export default function History() {
    const { history } = useSelector(state => state.historySlice);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        const data = document.querySelector(`.${style.history_box}`);
        data.scrollLeft = data.scrollWidth;
    }, [history]);

    const close = (name, e) => {
        e.stopPropagation();
        const idx = history.findIndex(item => item.name === name);
        if (idx === 0 && history.length > 1) { //删除第一个记录
            location.pathname === history[idx].path && navigate(history[history.length - 1].path);
            return dispatch(SetDelete({ name }));
        }
        if (idx === history.length - 1 && history.length > 1) { //删除最后一个记录
            location.pathname === history[idx].path && navigate(history[idx - 1].path);
            return dispatch(SetDelete({ name }));
        }
        if (idx === 0 && history.length === 1) return; //剩最后一个记录
        if (!(location.pathname === history[idx].path)) return dispatch(SetDelete({ name })); //记录数量不小于2，并删除的不是高亮记录

        navigate(history[history.length - 1].path);
        return dispatch(SetDelete({ name }));
    };

    const showPage = (uri, e) => {
        const ParenLeft = document.querySelector(`.${style.history_box}`);
        const currentNode = e.target.getBoundingClientRect().left - ParenLeft.getBoundingClientRect().left - 30;
        ParenLeft.scrollLeft = currentNode;
        !(location.pathname === uri) && navigate(uri);
    };

    return (
        <div className={style.history_box}>
            {history.map(item => {
                return (
                    <div
                        className={
                            location.pathname === item.path
                                ? `${style.item} ${style.item_active}`
                                : style.item
                        }
                        key={Math.random()}
                        onClick={e => showPage(item.path, e)}
                    >
                        <span>{item.name}</span>
                        <span onClick={e => close(item.name, e)}>
                            <CloseOutlined />
                        </span>
                    </div>
                );
            })}
        </div>
    );
}
