import React, { useState, useEffect } from 'react';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Layout, theme } from 'antd';
import WaterMark from 'watermark-component-for-react';
import storage from 'store';
import { Outlet } from 'react-router-dom';

import Sidebar from './components/sidebar';
import Origin from './components/origin/origin';
import History from './components/History/History';
import UserCenter from './components/UserCenter/UserCenter';

const { Header, Sider, Content } = Layout;

const App = () => {
    const [collapsed, setCollapsed] = useState(false);
    const [screenWid, setScreenWid] = useState({ minHeight: '100vh', maxHeight: '100vh', minWidth: '' });
    const userName = storage.get('userInfo') ? storage.get('userInfo').nickname : '';

    useEffect(() => {
        setScreenWid({ ...screenWid, minWidth: window.screen.width * 0.95 + 'px' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
        token: { colorBgContainer },
    } = theme.useToken();
    return (
        <WaterMark content={userName} width="150px" height="100px" font="16px Microsoft Yahei" globalAlpha={0.06}>
            <Layout style={screenWid}>
                <Sider
                    trigger={null}
                    collapsible
                    collapsed={collapsed}
                    width="238px"
                    theme="light"
                    style={{ overflowY: 'auto', maxHeight: '100vh', userSelect: 'none' }}
                >
                    <Sidebar collapsed={collapsed} />
                </Sider>
                <Layout className="site-layout">
                    <Header
                        style={{
                            padding: 0,
                            paddingLeft: 20,
                            display: 'flex',
                            alignItems: 'center',
                            background: colorBgContainer,
                        }}
                    >
                        {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                            className: 'trigger',
                            onClick: () => setCollapsed(!collapsed),
                        })}
                        <Origin />
                        <History />
                        <UserCenter />
                    </Header>
                    <Content
                        style={{
                            // margin: '0px 16px',
                            padding: 24,
                            minHeight: 280,
                            background: '#fbfaff',
                            overflow: 'hidden',
                        }}
                    >
                        <Outlet />
                    </Content>
                </Layout>
            </Layout>
        </WaterMark>
    );
};
export default App;
