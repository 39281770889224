/* eslint-disable import/no-anonymous-default-export */
const Internal = ()=>(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 6.5C9 5.39545 8.10455 4.5 7 4.5C5.89545 4.5 5 5.39545 5 6.5C5 7.60455 5.89545 8.5 7 8.5C8.10455 8.5 9 7.60455 9 6.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round"/>
        <path d="M18.5 6.5C18.5 5.39545 17.6046 4.5 16.5 4.5C15.3954 4.5 14.5 5.39545 14.5 6.5C14.5 7.60455 15.3954 8.5 16.5 8.5C17.6046 8.5 18.5 7.60455 18.5 6.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round"/>
        <path d="M2 6.5H5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
        <path d="M9 6.5H14.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
        <path d="M18.5 6.5H22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
        <path d="M5 14.5L2 17.5L5 20.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2 17.5H22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)
export default {Internal}