// eslint-disable-next-line import/no-anonymous-default-export
export default ({ request }) => ({
    // 密码修改
    managerEditPwd(data) {
        return request({
            url: 'admin/manager/edit_pwd',
            method: 'post',
            data,
        });
    },
    // 个人中心-上传头像
    commonUploadImg(data) {
        return request({
            url: 'admin/common/upload_img',
            method: 'post',
            data,
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    },
    // 个人中心-修改头像
    managerEditAvatar(data) {
        return request({
            url: 'admin/manager/edit_avatar',
            method: 'post',
            data,
        });
    },
    //选择账号列表
    getSelectManger() {
        return request({
            url: 'admin/manager/select_manger',
            method: 'get',
        });
    },
    //获取可用的企业列表
    getCompanyrSelectList() {
        return request({
            url: 'admin/company/select_list',
            method: 'get',
        });
    },
    //获取小说平台列表
    getNovelPlatformList(params) {
        return request({
            url: 'admin/novel_platform/list',
            method: 'get',
            params,
        });
    },
    // 获取可用的小说列表
    getSelectBookList(data) {
        return request({
            url: 'admin/book/get_select_book',
            method: 'post',
            data,
        });
    },
    // 获取可选择的渠道
    getChannelSelectList(data) {
        return request({
            url: 'admin/channel/get_channel_select_list',
            method: 'post',
            data,
        });
    },
    // 获取可用的客服
    getUsableKfList(params) {
        return request({
            url: 'admin/kf/get_select_kf',
            method: 'get',
            params,
        });
    },
    // 成员选择列表
    getAccountSelectList(params) {
        return request({
            url: 'admin/account/select_list',
            method: 'get',
            params,
        });
    },
    // 获取可用的客服欢迎语列表
    getSelectWelcome(params) {
        return request({
            url: 'admin/kf/get_select_welcome',
            method: 'get',
            params,
        });
    },
    // 获取可用小程序
    getSelectApp() {
        return request({
            url: 'admin/mini_program/get_select_app',
            method: 'get',
        });
    },
    // 获取群发详情
    getMsgInfo(params) {
        return request({
            url: 'admin/msg/msg_info',
            method: 'get',
            params
        });
    },
    // 获取成员二维码
    getQrcode(params) {
        return request({
            url: 'admin/account/get_qrcode',
            method: 'get',
            params
        });
    },
});
