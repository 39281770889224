// eslint-disable-next-line import/no-anonymous-default-export
export default ({ request }) => ({
    getRoleList(params) {
        return request({
            url: 'admin/role/list',
            method: 'get',
            params,
        });
    },
    editStatus(params) {
        return request({
            url: 'admin/role/edit_status',
            method: 'get',
            params,
        });
    },
    roleDel(params) {
        return request({
            url: 'admin/role/del',
            method: 'get',
            params,
        });
    },
    roleAdd(data) {
        return request({
            url: 'admin/role/add',
            method: 'post',
            data,
        });
    },
    roleEdit(data) {
        return request({
            url: 'admin/role/edit',
            method: 'post',
            data,
        });
    },
});
