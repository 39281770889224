import storage from 'store';

import Pege404 from '@/views/errorPage/404';
import Pege403 from '@/views/errorPage/403';

function Intercept({ component, pagePath }) {
    const power = storage.get('mergeMenu');
    const isLogin = storage.get('isLogin');

    if (!power && isLogin) {
        return <Pege404 />;
    }
    const state = !power.some(el => {
        let bool = '';
        if (el.children && el.children.length) {
            bool = el.children.some(item => item.path === pagePath);
        }
        return bool || el.path === pagePath;
    });
    if (state) {
        return <Pege403 />;
    }
    
    return component;
}

export default Intercept;
