import Intercept from './intercept';
import Home from '../layouts';
import {whiteList,powerList} from "./routeList" 

const mergeRouterList = () => {
    function intercept(list,pel='') {
        let arr = [];
        list.forEach(item => {
            if (item.children && item.children.length) {
                let mergePath = '/' + item.path;
                arr.push({
                    ...item,
                    children: intercept(item.children,mergePath),
                });
            } else {
                let mergePath = pel +'/' + item.path;
                arr.push({
                    ...item,
                    element : <Intercept component={item.element} pagePath={mergePath} />
                })
            }
            return item;
        });
        return arr;
    }

    return [
        ...whiteList,
        {
            path: '/home',
            element: <Home />,
            children: intercept(powerList),
        },
    ];
};

export { mergeRouterList, powerList };
