// eslint-disable-next-line import/no-anonymous-default-export
export default ({ request }) => ({
    getMiniProgramList(params) {
        return request({
            url: 'admin/mini_program/list',
            method: 'get',
            params,
        });
    },
    MiniProgramEditStatus(params) {
        return request({
            url: 'admin/mini_program/edit_status',
            method: 'get',
            params,
        });
    },
    MiniProgramDel(params) {
        return request({
            url: 'admin/mini_program/del',
            method: 'get',
            params,
        });
    },
    miniProgramAdd(data) {
        return request({
            url: 'admin/mini_program/add',
            method: 'post',
            data
        });
    },
    miniProgramInfoConfig(params) {
        return request({
            url: 'admin/user_bind/info_config',
            method: 'get',
            params,
        });
    },
    miniProgramEditConfig(data) {
        return request({
            url: 'admin/user_bind/edit_config',
            method: 'post',
            data
        });
    },
    getUserBindLog(params) {
        return request({
            url: 'admin/user_bind/send_log',
            method: 'get',
            params,
        });
    },
});