import React from 'react';
import { useNavigate } from 'react-router-dom';

import style from './errPage.module.scss';
import { ReactComponent as Page404 } from '@/assets/imgs/err_page/404.svg';

export default function ErrPage404() {
    const navigate = useNavigate();

    const handelRollback = () => {
        navigate('/home', { replace: true });
    };
    return (
        <div className={style.err_page}>
            <div>
                <Page404 />
            </div>
            <p>您访问的页面不存在</p>
            <button onClick={handelRollback}>刷新页面</button>
        </div>
    );
}
