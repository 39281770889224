import React, { useState } from 'react';

import { Select } from 'antd';

const provinceData = ['全部','成都网络科技有限公司', '四川网络科技有限公司'];
const cityData = {
    '全部':['全部'],
    '成都网络科技有限公司': ['小说助手-帆帆'],
    '四川网络科技有限公司': ['小说助手-小花', '小说助手-菊花'],
};

export default function Origin() {
    const [cities, setCities] = useState(cityData[provinceData[0]]);
    const [secondCity, setSecondCity] = useState(cityData[provinceData[0]][0]);
    const handleProvinceChange = value => {
        setCities(cityData[value]);
        setSecondCity(cityData[value][0]);
    };
    const onSecondCityChange = value => {
        setSecondCity(value);
    };

    return (
        <div>
            <Select
                defaultValue={provinceData[0]}
                style={{
                    width: 140,
                    marginLeft: '28px',
                }}
                disabled
                onChange={handleProvinceChange}
                options={provinceData.map(province => ({
                    label: province,
                    value: province,
                }))}
            />
            <Select
                style={{
                    width: 140,
                    marginLeft: '10px',
                }}
                disabled
                value={secondCity}
                onChange={onSecondCityChange}
                options={cities.map(city => ({
                    label: city,
                    value: city,
                }))}
            />
        </div>
    );
}
