// eslint-disable-next-line import/no-anonymous-default-export
export default ({ request }) => ({
    getOaList(params) {
        return request({
            url: 'admin/oa/list',
            method: 'get',
            params,
        });
    },
    getAuthUrl() {
        return request({
            url: 'admin/oa/get_auth_url',
            method: 'get',
        });
    },
    oaListChangeNovelPlatform(params) {
        return request({
            url: 'admin/oa/change_novel_platform',
            method: 'get',
            params,
        });
    },
    oaEditDefault(params) {
        return request({
            url: 'admin/oa/edit_default',
            method: 'get',
            params,
        });
    },
});
