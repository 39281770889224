import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    history: [],
};

const historySlice = createSlice({
    name: 'historySlice',
    initialState,
    reducers: {
        SetHistory: (state, action) => {
            const { jurisdiction, pathname } = action.payload;
            const pash_head = '/home';

            function setPath(params) {
                return pash_head + params.slice(0, params.lastIndexOf('/'));
            }

            Array.isArray(jurisdiction) &&
                jurisdiction.length &&
                jurisdiction.some(item => {
                    if (setPath(item.path) === pathname) {
                        // eslint-disable-next-line no-unused-expressions
                        !state.history.some(state => state.path === setPath(item.path))
                            ? (state.history = [...state.history, { path: setPath(item.path), name: item.name }])
                            : null;
                        return item;
                    } else {
                        return (
                            item.children &&
                            item.children.length &&
                            item.children.some(el => {
                                if (pash_head + el.path === pathname) {
                                    // eslint-disable-next-line no-unused-expressions
                                    !state.history.some(state => state.path === pash_head + el.path)
                                        ? (state.history = [...state.history, { path: pash_head + el.path, name: el.name }])
                                        : null;
                                }
                                return pash_head + el.path === pathname;
                            })
                        );
                    }
                });
        },
        SetDelete: (state, action) => {
            const { name } = action.payload;
            state.history.splice(
                state.history.findIndex(item => item.name === name),
                1
            );
        },
        SetEmpty: state => {
            state.history = [];
        },
    },
});

export const { SetHistory, SetDelete, SetEmpty } = historySlice.actions;

export default historySlice.reducer;
