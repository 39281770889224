// import { BrowserRouter } from 'react-router-dom';
import { HashRouter } from 'react-router-dom';

import Router from './router/appRouter';

function App() {
    console.log(
        "'\n" +
            "'   _____ _   _     _     _   _   ____        ____   _   _  _   _\n" +
            "'  |__  /| | | |   / \\   | \\ | | / ___|      / ___| | | | || | | |\n" +
            "'    / / | |_| |  / _ \\  |  \\| || |  _       \\___ \\ | |_| || | | |\n" +
            "'   / /_ |  _  | / ___ \\ | |\\  || |_| |       ___) ||  _  || |_| |\n" +
            "'  /____||_| |_|/_/   \\_\\|_| \\_| \\____|      |____/ |_| |_| \\___/\n" +
            "'\n" +
            "'\n" +
            "'  :: 企微管理 ::                (v1.0.0)"
    );

    return (
        <>
            <HashRouter>
                <Router />
            </HashRouter>
        </>
    );
}

export default App;
