// eslint-disable-next-line import/no-anonymous-default-export
export default ({ request }) => ({
    addMoveTask(params) {
        return request({
            url: 'admin/user/add_move_task',
            method: 'get',
            params,
        });
    },
    moveTaskList(params) {
        return request({
            url: 'admin/user/move_task_list',
            method: 'get',
            params,
        });
    },
    runMoveTask(params) {
        return request({
            url: 'admin/user/run_move_task',
            method: 'get',
            params,
        });
    },
});