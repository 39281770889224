// eslint-disable-next-line import/no-anonymous-default-export
export default ({ request }) => ({
    novelPlatformDel(params) {
        return request({
            url: 'admin/novel_platform/del',
            method: 'get',
            params,
        });
    },
    novelPlatformAdd(data) {
        return request({
            url: 'admin/novel_platform/add',
            method: 'post',
            data,
        });
    },
});
