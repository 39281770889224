import React from 'react';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';

import style from './Loading.module.scss';

export default function Loading() {
    const { loading } = useSelector(state => state.userSlice);

    if (!loading) {
        return null;
    }

    return (
        <div className={style.loading}>
            <Spin tip="加载中" size="large" spinning={loading}></Spin>
        </div>
    );
}
