// eslint-disable-next-line import/no-anonymous-default-export
export default ({ request }) => ({
    getTagList(params) {
        return request({
            url: 'admin/tag/list',
            method: 'get',
            params,
        });
    },
    tagAdd(data) {
        return request({
            url: 'admin/tag/add',
            method: 'post',
            data,
        });
    },
    tagDel(params) {
        return request({
            url: 'admin/tag/del',
            method: 'get',
            params,
        });
    },
    tagEditStatus(params) {
        return request({
            url: 'admin/tag/edit_status',
            method: 'get',
            params,
        });
    },
});
