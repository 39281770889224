// eslint-disable-next-line import/no-anonymous-default-export
export default ({ request }) => ({
    kfListWelcome(params) {
        return request({
            url: 'admin/kf/list_welcome',
            method: 'get',
            params,
        });
    },
    kfAddWelcome(data) {
        return request({
            url: 'admin/kf/add_welcome',
            method: 'post',
            data,
        });
    },
    kfEditWelcome(data) {
        return request({
            url: 'admin/kf/edit_welcome',
            method: 'post',
            data,
        });
    },
    kfEditStatusWelcome(params) {
        return request({
            url: 'admin/kf/edit_status_welcome',
            method: 'get',
            params,
        });
    },
});
