// react动态加载组件 @loadable/component
import loadable from '@loadable/component';

// 内部渠道
const ChannelList = loadable(() => import('@/views/internalChannels/InternalChannels'));

// //数据管理
// const WelcomesData = loadable(() => import('@/views/dataManage/WelcomesData'));

// 权限管理
const RoleManage = loadable(() => import('@/views/privilegeManage/RoleManage'));
const AccountManage = loadable(() => import('@/views/privilegeManage/AccountManage'));
const MenuList = loadable(() => import('@/views/privilegeManage/MenuList'));

// 系统设置
const BooksConfiguration = loadable(() => import('@/views/systemSettings/BooksConfiguration'));
const PlatformConfiguration = loadable(() => import('@/views/systemSettings/PlatformConfiguration'));
const EmsCnplConfiguration = loadable(() => import('@/views/systemSettings/EmsCnplConfiguration'));
const OnlineConfiguration = loadable(() => import('@/views/systemSettings/OnlineConfiguration'));
const AutoaActive = loadable(() => import('@/views/systemSettings/AutoaActive'));
const MiniProgram = loadable(() => import('@/views/systemSettings/MiniProgram'));

// 活动中心
const EmsCnpl = loadable(() => import('@/views/activityCenter/EmsCnpl'));
const QiWei = loadable(() => import('@/views/activityCenter/QiWei'));

const list_1 = [
    {
        icon: 'Internal',
        name: '内部渠道',
        path: 'internalChannels',
        id: 15,
        pid: 0,
        children: [
            {
                id: 76,
                pid: 15,
                name: '渠道列表',
                path: 'channelList',
                element: <ChannelList />,
            },
        ],
    },
    // {
    //     icon: 'Data',
    //     name: '数据管理',
    //     path: 'dataManage',
    //     id:9,
    //     pid:0,
    //     children: [
    //         {
    //             id:99,
    //             pid:0,
    //             name: '欢迎语数据-客服号',
    //             path: 'welcomesdata',
    //             element: <WelcomesData />,
    //         },
    //     ],
    // },
    {
        icon: 'Permissions',
        name: '权限管理',
        path: 'privilegeManage',
        id: 16,
        pid: 0,
        children: [
            {
                id: 82,
                pid: 16,
                name: '角色管理',
                path: 'RoleManage',
                element: <RoleManage />,
            },
            {
                id: 83,
                pid: 16,
                name: '账号管理',
                path: 'AccountManage',
                element: <AccountManage />,
            },
            {
                id: 24,
                pid: 16,
                name: '菜单列表',
                path: 'MenuList',
                element: <MenuList />,
            },
        ],
    },
    {
        icon: 'System',
        name: '系统设置',
        path: 'systemSettings',
        id: 23,
        pid: 0,
        children: [
            {
                id: 129,
                pid: 23,
                name: '平台配置',
                path: 'PlatformConfiguration',
                element: <PlatformConfiguration />,
            },
            {
                id: 130,
                pid: 23,
                name: '书籍配置',
                path: 'BooksConfiguration',
                element: <BooksConfiguration />,
            },
            {
                id: 131,
                pid: 23,
                name: '公众号配置',
                path: 'EmsCnplConfiguration',
                element: <EmsCnplConfiguration />,
            },
            {
                id: 148,
                pid: 23,
                name: '在线配置',
                path: 'OnlineConfiguration',
                element: <OnlineConfiguration />,
            },
            {
                id: 149,
                pid: 23,
                name: '自动活跃',
                path: 'AutoaActive',
                element: <AutoaActive />,
            },
            {
                id: 166,
                pid: 23,
                name: '小程序配置',
                path: 'MiniProgram',
                element: <MiniProgram />,
            },
        ],
    },
    {
        icon: 'Gift',
        name: '活动中心',
        path: 'activityCenter',
        id: 158,
        pid: 0,
        children: [
            {
                id: 159,
                pid: 158,
                name: '公众号-抽奖',
                path: 'EmsCnpl',
                element: <EmsCnpl />,
            },
            {
                id: 160,
                pid: 158,
                name: '企微-抽奖',
                path: 'QiWei',
                element: <QiWei />,
            },
        ],
    },
];

export default list_1;
