const AddImg = ()=>(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22 12C22 11.4477 21.5523 11 21 11C20.4477 11 20 11.4477 20 12H22ZM12 4C12.5523 4 13 3.55229 13 3C13 2.44771 12.5523 2 12 2V4ZM19.5 20H4.5V22H19.5V20ZM4 19.5V4.5H2V19.5H4ZM20 12V19.5H22V12H20ZM4.5 4H12V2H4.5V4ZM4.5 20C4.22386 20 4 19.7761 4 19.5H2C2 20.8807 3.11928 22 4.5 22V20ZM19.5 22C20.8807 22 22 20.8807 22 19.5H20C20 19.7761 19.7761 20 19.5 20V22ZM4 4.5C4 4.22386 4.22385 4 4.5 4V2C3.11929 2 2 3.11928 2 4.5H4Z" fill="currentColor"/>
        <path d="M3 17.5L8.34655 12.599C8.71945 12.2571 9.28895 12.2476 9.67305 12.5769L16 18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14 15.5L16.3867 13.1132C16.7386 12.7614 17.2957 12.7218 17.6938 13.0204L21 15.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15 6H21" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M18 3V9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

// eslint-disable-next-line import/no-anonymous-default-export
export default {AddImg}