// eslint-disable-next-line import/no-anonymous-default-export
export default ({ request }) => ({
    getExamineUser(params) {
        return request({
            url: 'admin/system/get_examine_user',
            method: 'get',
            params,
        });
    },
    editExamineUser(data) {
        return request({
            url: 'admin/system/edit_examine_user',
            method: 'post',
            data,
        });
    },
});
