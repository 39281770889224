// eslint-disable-next-line import/no-anonymous-default-export
export default ({ request }) => ({
    getOnlineStatus(params) {
        return request({
            url: 'admin/account/online_status',
            method: 'get',
            params,
        });
    },
    getDeviceGroup() {
        return request({
            url: 'admin/account/device_group',
            method: 'get',
        });
    },
    accountDevice(params) {
        return request({
            url: 'admin/account/device',
            method: 'get',
            params,
        });
    },
    accountEditDevice(params) {
        return request({
            url: 'admin/account/edit_device',
            method: 'get',
            params,
        });
    },
    accountDeviceCount(params) {
        return request({
            url: 'admin/account/device_count',
            method: 'get',
            params,
        });
    },
});
