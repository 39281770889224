// eslint-disable-next-line import/no-anonymous-default-export
export default ({ request }) => ({
    getMsgContentList(params) {
        return request({
            url: 'admin/msg_content/list',
            method: 'get',
            params,
        });
    },
    addMsgContent(data) {
        return request({
            url: 'admin/msg_content/add',
            method: 'post',
            data,
        });
    },
    MsgContentEditStatus(params) {
        return request({
            url: 'admin/msg_content/edit_status',
            method: 'get',
            params,
        });
    },
    MsgContentDel(params) {
        return request({
            url: 'admin/msg_content/del',
            method: 'get',
            params,
        });
    },
    MsgContentInfo(params) {
        return request({
            url: 'admin/msg_content/info',
            method: 'get',
            params,
        });
    },
});
