// eslint-disable-next-line import/no-anonymous-default-export
export default ({ request }) => ({
    getMenuList() {
        return request({
            url: 'admin/menu/list',
            method: 'get',
        });
    },
    menuAdd(data) {
        return request({
            url: 'admin/menu/add',
            method: 'post',
            data,
        });
    },
    menuEdit(data) {
        return request({
            url: 'admin/menu/edit',
            method: 'post',
            data,
        });
    },
    menuEditSort(params) {
        return request({
            url: 'admin/menu/edit_sort',
            method: 'get',
            params,
        });
    },
});
