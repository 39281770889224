// eslint-disable-next-line import/no-anonymous-default-export
export default ({ request }) => ({
    getMsgDelayed(params) {
        return request({
            url: 'admin/msg_delayed/list',
            method: 'get',
            params,
        });
    },
    addMsgDelayed(data) {
        return request({
            url: 'admin/msg_delayed/add',
            method: 'post',
            data,
        });
    },
    msgDelayedEditStatus(params) {
        return request({
            url: 'admin/msg_delayed/edit_status',
            method: 'get',
            params,
        });
    },
    msgDelayedDel(params) {
        return request({
            url: 'admin/msg_delayed/del',
            method: 'get',
            params,
        });
    },
    msgDelayedSendMsgLog(params) {
        return request({
            url: 'admin/msg_delayed/send_msg_log',
            method: 'get',
            params,
        });
    },
    msgDelayedMsgData(params) {
        return request({
            url: 'admin/msg_delayed/msg_data',
            method: 'get',
            params,
        });
    },
    msgDelayedAllMsgData(params) {
        return request({
            url: 'admin/msg_delayed/all_msg_data',
            method: 'get',
            params,
        });
    },
    msgDelayedInfo(params) {
        return request({
            url: 'admin/msg_delayed/info',
            method: 'get',
            params,
        });
    },
});
