// eslint-disable-next-line import/no-anonymous-default-export
export default ({ request }) => ({
    login(data) {
        return request({
            url: 'admin/manager/login',
            method: 'post',
            data,
        });
    },
    getInfo() {
        return request({
            url: 'admin/manager/manager_info',
            method: 'get',
        });
    },
    getPower() {
        return request({
            url: 'admin/manager/manager_menu',
            method: 'get',
        });
    },
});
