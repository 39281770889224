/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip, Button, ConfigProvider, Form, Input, message, Upload } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import './UserCenter.scss';
import { userInfo } from '@/store/feature/userSlice';
import { ExportOutlined, CloseOutlined } from '@ant-design/icons';
import { SetEmpty } from '@/store/feature/historySlice';
import { DelUserInfo } from '@/store/feature/userSlice';
import defaultAvatar from '@/assets/imgs/default_avatar.png';
import { ReactComponent as RiRditLine } from '@/assets/imgs/ri_edit_line.svg';
import { ReactComponent as Key } from '@/assets/imgs/key.svg';
import { ReactComponent as Exit } from '@/assets/imgs/exit.svg';
import { ReactComponent as Kou } from '@/assets/imgs/Kou.svg';
import { ReactComponent as Successful } from '@/assets/imgs/successful.svg';
import { ReactComponent as UploadHead } from '@/assets/imgs/upload_head.svg';
import { BASE_URL } from '@/utils/config.js';

function UserPopupWindow() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let {
        user_info: { avatar, account, role_name, nickname, from_type },
    } = useSelector(state => state.userSlice);

    const [state, setState] = useState({ exit: false, modify_pw: false, redirect: false, modify_picture: true });

    if (avatar) {
        avatar = BASE_URL + avatar;
    } else {
        avatar = defaultAvatar;
    }

    const onClickInit = () => {
        setState({ ...state, exit: false, modify_pw: false, modify_picture: true });
    };

    const handelExit = () => {
        dispatch(SetEmpty());
        dispatch(DelUserInfo());
        navigate('/login', { replace: true });
    };

    const confirm_exit = () => {
        setState({ ...state, exit: true });
    };

    const modify_pw = () => {
        setState({ ...state, modify_pw: true });
    };

    const redirectCallBack = () => {
        setState({ ...state, redirect: true });
    };

    const onClickModifyPicture = () => {
        setState({ ...state, modify_picture: false });
    };

    const reset = () => {
        onClickInit();
    };

    return (
        <div className="user-popup-window">
            {state.redirect ? (
                <RedirectionLogin state={state} handelExitCallback={handelExit} />
            ) : (
                <>
                    {state.exit || state.modify_pw || !state.modify_picture ? (
                        <span className="user-popup-cancel" onClick={onClickInit}>
                            <CloseOutlined />
                        </span>
                    ) : null}
                    <div>
                        <img src={avatar} className="popup-img" draggable={false} />
                        {(!state.exit && state.modify_pw) || (state.exit && !state.modify_pw) || !state.modify_picture ? null : (
                            <span onClick={onClickModifyPicture}>
                                <RiRditLine />
                            </span>
                        )}
                    </div>
                    <p className="popup-tilte">{account}</p>
                    {!state.modify_picture ? (
                        <ModifyPicture resetCallBack={reset} />
                    ) : (
                        <>
                            {state.modify_pw ? (
                                <ModifyPw redirectCallBack={redirectCallBack} />
                            ) : (
                                <>
                                    <span className="popup-line"></span>
                                    {state.exit ? (
                                        <ConfirmExit handelExit={handelExit} />
                                    ) : (
                                        <AccountText
                                            role_name={role_name}
                                            nickname={nickname}
                                            from_type={from_type}
                                            confirm_exit={confirm_exit}
                                            modify_pw={modify_pw}
                                        />
                                    )}
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    );
}

function AccountText(props) {
    let { nickname, role_name, confirm_exit, modify_pw, from_type } = props;

    return (
        <>
            <div className="popup-source-container">
                <p className="popup-source">账号：{nickname}</p>
                <p className="popup-source">角色：{role_name}</p>
                <p className="popup-source">来源：{+from_type === 1 ? '飞书' : '自建'}</p>
            </div>
            <div className="popup-set-container">
                <div className="popup-set-item" onClick={modify_pw}>
                    <Key />
                    <span>修改密码</span>
                </div>
                <div className="popup-set-item" onClick={confirm_exit}>
                    <Exit />
                    <span>退出登录</span>
                </div>
            </div>
        </>
    );
}

function ModifyPw(props) {
    const [form] = Form.useForm();
    const [stata, setState] = useState({ pw1: '', pw2: '' });

    const onFinish = async values => {
        let { old_pwd, new_pwd } = values;
        React.$API
            .managerEditPwd({ old_pwd, new_pwd })
            .then(result => {
                let { code, err_msg = '修改失败' } = result;
                if (code === 0) {
                    props.redirectCallBack();
                } else {
                    message.error(err_msg);
                }
            })
            .catch(err => {});
    };

    const onChangePw = (e, type) => {
        if (type === 'pw1') {
            setState({ ...stata, pw1: e.target.value });
        } else {
            setState({ ...stata, pw2: e.target.value });
        }
    };

    const onClicksubmit = () => {
        if (stata.pw1 === stata.pw2) {
            form.submit();
        } else {
            message.error('新密码不一致');
        }
    };

    return (
        <div className="popup-modify-capacitor">
            <Form
                form={form}
                name="basic"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item
                    name="old_pwd"
                    style={{ marginTop: '30px' }}
                    rules={[
                        {
                            required: true,
                            message: '原密码不能为空!',
                        },
                    ]}
                >
                    <div className="input_box">
                        <Input.Password bordered={false} placeholder="原密码" />
                    </div>
                </Form.Item>

                <Form.Item
                    style={{ marginTop: '30px' }}
                    name="Password"
                    rules={[
                        {
                            required: true,
                            message: '新密码不能为空!',
                        },
                    ]}
                >
                    <div className="input_box">
                        <Input.Password bordered={false} placeholder="新密码" onChange={e => onChangePw(e, 'pw1')} />
                    </div>
                </Form.Item>
                <Form.Item
                    style={{ marginTop: '30px' }}
                    name="new_pwd"
                    rules={[
                        {
                            required: true,
                            message: '新密码不能为空!',
                        },
                    ]}
                >
                    <div className="input_box">
                        <Input.Password bordered={false} placeholder="新密码确认" onChange={e => onChangePw(e, 'pw2')} />
                    </div>
                </Form.Item>

                <Form.Item style={{ marginTop: '30px' }}>
                    <div className="submit">
                        <Button type="primary" onClick={onClicksubmit}>
                            确认修改
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </div>
    );
}

function RedirectionLogin(props) {
    let [num, setNum] = useState(3);
    useEffect(() => {
        let timeID = setInterval(() => {
            if (num <= 1) {
                clearInterval(timeID);
                timeID = null;
                props.handelExitCallback();
            }
            setNum(--num);
        }, 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="popup-redirection-login">
            <Successful />
            <div className="pw-container">
                <Kou />
                <span className="pw">密码修改成功</span>
            </div>
            <p>您已成功修改密码，请返回登录页用新密码进行登录</p>
            <div>
                <Button type="primary" icon={<ExportOutlined />} onClick={() => props.handelExitCallback()}>
                    返回登录{` (${num}s)`}
                </Button>
            </div>
        </div>
    );
}

function ConfirmExit(props) {
    return (
        <div className="popup-confirm-container">
            <p>确定要退出当前账号吗？</p>
            <p>退出后可重新通过飞书扫码或账号密码进行登录操作</p>
            <div>
                <Button type="primary" icon={<ExportOutlined />} onClick={props.handelExit}>
                    确定退出
                </Button>
            </div>
        </div>
    );
}

//上传图片尺寸限制
function checkImgWH(file, w, h) {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (e) {
            let img = new Image();
            img.src = e.target.result;
            img.onload = () => {
                if (img.width <= w && img.height <= h) {
                    resolve();
                } else {
                    message.error(`请上传宽高 ${w}*${h} 以内的图片！`);
                    reject();
                }
            };
        };
    });
}

function ModifyPicture(props) {
    const [state, staState] = useState({ avatar: '' });
    const dispatch = useDispatch();

    const fileProps = {
        name: 'file',
        showUploadList: false,
        customRequest: options => {
            const { file } = options;
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function (e) {
                const form = new FormData();
                form.append('file', file);
                form.append('type', 'avatar');
                React.$API
                    .commonUploadImg(form)
                    .then(res => {
                        let {
                            code,
                            data: { loca_path },
                            err_msg = '上传失败',
                        } = res;
                        if (code === 0) {
                            staState({ ...state, avatar: loca_path });
                        } else {
                            message.error(err_msg);
                        }
                    })
                    .catch(err => {});
            };
        },
        beforeUpload: async file => {
            let size = await checkImgWH(file, 200, 200);

            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                message.error('请上传 JPG/PNG 格式的文件!');
            }
            const isLt1M = file.size / 1024 / 1024 < 1;
            if (!isLt1M) {
                message.error('请上传在1MB以内的图片!');
            }
            return isJpgOrPng && isLt1M && size;
        },
    };

    const onClickSubmit = () => {
        React.$API
            .managerEditAvatar({ avatar: state.avatar })
            .then(result => {
                let { code, err_msg = '修改失败' } = result;
                if (code === 0) {
                    message.success('修改成功');
                    props.resetCallBack();
                    dispatch(userInfo());
                } else {
                    message.error(err_msg);
                }
            })
            .catch(err => {});
    };

    return (
        <div className="popup-modify-picture">
            <p>修改头像</p>
            <div>
                {state.avatar ? (
                    <img src={BASE_URL + state.avatar} alt="" className="upload-img" />
                ) : (
                    <Upload {...fileProps}>
                        <span className='popup-upload-hover'>
                            <UploadHead />
                        </span>
                    </Upload>
                )}
            </div>
            <div>
                <Button type="primary" onClick={onClickSubmit}>
                    确认修改
                </Button>
            </div>
        </div>
    );
}

export default function UserCenter() {
    let {
        user_info: { avatar, account },
    } = useSelector(state => state.userSlice);

    if (avatar) {
        avatar = BASE_URL + avatar;
    } else {
        avatar = defaultAvatar;
    }

    return (
        <div className="user-center">
            <img src={avatar} className="user-img" draggable={false} />
            <ConfigProvider
                theme={{
                    token: {
                        colorLink: '#333333',
                        colorLinkHover: '#8C8BDC',
                        colorLinkActive: '4544BF',
                    },
                }}
            >
                <Tooltip placement="left" title={<UserPopupWindow />} color="transparent" trigger="click" arrow={false} destroyTooltipOnHide>
                    <Button type="link">{account ? account : '无'}</Button>
                </Tooltip>
            </ConfigProvider>
        </div>
    );
}
