/* eslint-disable import/no-anonymous-default-export */
const WebPage = ()=>(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.5 20H3.5C2.67158 20 2 19.3285 2 18.5V5.5C2 4.67158 2.67158 4 3.5 4H20.5C21.3285 4 22 4.67158 22 5.5V12.4706" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2 5.5C2 4.67158 2.67158 4 3.5 4H20.5C21.3285 4 22 4.67158 22 5.5V10H2V5.5Z" stroke="currentColor" strokeWidth="1.5"/>
        <path d="M16 17.5H22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M19 14.5V20.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4 7C4 6.44772 4.44772 6 5 6C5.55228 6 6 6.44772 6 7C6 7.55228 5.55228 8 5 8C4.44772 8 4 7.55229 4 7Z" fill="currentColor"/>
        <path d="M7 7C7 6.44772 7.44772 6 8 6C8.55228 6 9 6.44772 9 7C9 7.55228 8.55228 8 8 8C7.44772 8 7 7.55229 7 7Z" fill="currentColor"/>
    </svg>
)

export default {WebPage}