import { configureStore } from "@reduxjs/toolkit"

import userSlice from "./feature/userSlice"
import historySlice from "./feature/historySlice"

export default configureStore({
    reducer: {
        userSlice,
        historySlice
    }
})