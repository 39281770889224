/* eslint-disable import/no-anonymous-default-export */
const WeChat = ()=>(
    <svg width="15" height="14" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.93701 4.5815C6.90814 2.94963 5.57627 1.63565 3.9375 1.63565C2.28064 1.63565 0.9375 2.97878 0.9375 4.63565C0.9375 5.41754 1.23662 6.12957 1.72667 6.66353L1.50196 8.01547L2.83594 7.4425C3.42369 7.63793 3.97837 7.6868 4.5 7.58907" stroke="currentColor" strokeWidth="0.765958" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2.83594 4.34914C3.06893 4.34914 3.25781 4.15886 3.25781 3.92413C3.25781 3.68942 3.06893 3.49912 2.83594 3.49912C2.60295 3.49912 2.41406 3.68942 2.41406 3.92413C2.41406 4.15886 2.60295 4.34914 2.83594 4.34914Z" fill="currentColor"/>
        <path d="M4.52344 4.34914C4.75643 4.34914 4.94531 4.15886 4.94531 3.92413C4.94531 3.68942 4.75643 3.49912 4.52344 3.49912C4.29045 3.49912 4.10156 3.68942 4.10156 3.92413C4.10156 4.15886 4.29045 4.34914 4.52344 4.34914Z" fill="currentColor"/>
        <path d="M7.26784 7.99847C6.94003 8.24238 6.53376 8.38672 6.09375 8.38672C5.00644 8.38672 4.125 7.50529 4.125 6.41797C4.125 5.33066 5.00644 4.44922 6.09375 4.44922C7.18106 4.44922 8.0625 5.33066 8.0625 6.41797C8.0625 6.71792 7.99543 7.0022 7.87545 7.25662" stroke="currentColor" strokeWidth="0.765958" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.87543 7.25591L8.06248 8.38601L7.26782 7.99775" stroke="currentColor" strokeWidth="0.765958" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6.69165 6.28697C6.5169 6.28697 6.37524 6.14426 6.37524 5.96822C6.37524 5.79217 6.5169 5.64947 6.69165 5.64947C6.8664 5.64947 7.00806 5.79217 7.00806 5.96822C7.00806 6.14426 6.8664 6.28697 6.69165 6.28697Z" fill="currentColor"/>
        <path d="M5.42578 6.28697C5.25103 6.28697 5.10938 6.14426 5.10938 5.96822C5.10938 5.79217 5.25103 5.64947 5.42578 5.64947C5.60053 5.64947 5.74219 5.79217 5.74219 5.96822C5.74219 6.14426 5.60053 6.28697 5.42578 6.28697Z" fill="currentColor"/>
    </svg>
)

export default {WeChat}