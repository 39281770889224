import React from 'react';
import { useSearchParams } from 'react-router-dom';

import PostersImg1 from '@/assets/imgs/image1.png';
import PostersImg2 from '@/assets/imgs/image2.png';
import PostersImg3 from '@/assets/imgs/image3.png';
import PostersImg4 from '@/assets/imgs/image4.png';
import PostersImg5 from '@/assets/imgs/image5.png';
import style from './Posters.module.scss';

export default function Posters() {
    const [data] = useSearchParams();
    let qr_code = data.get('qr_code');
    let style_option = data.get('style');
    let option = '';

    document.title = '\u200E';
    let head = document.querySelector('head');
    let meta = document.createElement('meta');
    meta.name = 'viewport';
    meta.content = 'width=device-width, initial-scale=1, shrink-to-fit=no,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no';
    head.append(meta);

    switch (style_option) {
        case '4':
            option = PostersImg5;
            break;

        case '3':
            option = PostersImg3;
            break;

        case '2':
            option = PostersImg2;
            break;

        case '-1':
            option = PostersImg4;
            break;

        default:
            option = PostersImg1;
            break;
    }

    return (
        <div className={style.posters_container}>
            <div>
                <div className={style_option === '3' ? 'style3' : style_option === '4' ? 'style4' : ''}>
                    <img src={qr_code} alt="加载失败" width="100%" height="100%" />
                </div>
                <img src={option} alt="加载失败" width="100%" height="100%" />
            </div>
        </div>
    );
}
